.home-page {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    color: white;
    background-color: black;
    overflow: hidden;
    padding-left: 4%;
    padding-right: 4%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    z-index: 2;
    background-color: black;

    .logo {
        height: 50px;
    }

}


.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.main-content {
    z-index: 2;
    display: flex;
    flex-direction: column;
    margin-bottom: 8%;
}

.main-content h1 {
    font-size: 4.5em;
    margin-bottom: 10px;
}

.main-content h2 {
    font-size: 4em;
    color: #00b4d8;
}

.typing {
    display: none; /* Hide all typing headers initially */
    overflow: hidden; /* Ensures no text overflow during typing effect */
    white-space: nowrap; /* Prevents text wrapping during typing effect */
    background: -webkit-linear-gradient(left, cyan, magenta, pink);
    font-size: 48px;
    animation: typing 3.5s steps(40, end) forwards, blink-caret .75s step-end infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
  }
  
  /* Typing and deleting animations */
  @keyframes typing {
    from { width: 0; }
    to { width: 100%; }
  }
  
  @keyframes deleting {
    from { width: 100%; }
    to { width: 0; }
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent; }
    50% { border-color: none; }
  }
  



.footer-links .link:last-child {
    border: none;
}

.footer-links .link:hover {
    background: linear-gradient(to right, rgba(171, 69, 69, 0.7), rgba(255, 0, 150, 0.7));
    width: 100%;

}

.footer-links .link span {
    width: 100%;
}


.footer-links {
    display: flex;
    z-index: 2;
    justify-content: center;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    background-color: rgba(189, 189, 189, 0.166);
    height: 100%;
    max-height: 150px;
    overflow: hidden;
    .links-container {
        display: flex;
        width: 100%;
        .link {
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            padding: 20px;
            transition: background-color 0.3s;
            flex: 1;
            margin: 0;
            font-size: 1em;
            border-right: 1px solid rgba(255, 255, 255, 0.3);
            line-height: 1.5;
            color: rgb(204, 204, 204);

            .default-text {
                transition: opacity 0.3s ease;
            }

            .hover-text {
                opacity: 0;
                transition: opacity 0.3s ease;
                overflow: hidden;
            }

            &:hover .default-text {
                opacity: 0;
                flex: 1;
            }

            &:hover .hover-text {
                opacity: 1;
                flex: 2;

            }
        }
    }
}