.main-container-third {
    height: 70vh;
    color: white;
    position: relative;
    box-sizing: border-box;
    z-index: 2;
    background-color: #b1daff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}


.content-third {
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
gap:20px
   
}
.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}
.logo {
    height: 80px;
}

.button {
    background: linear-gradient(90deg, #1e90ff 0%, #ff69b4 100%);
    border: none;
    border-radius: 20px;
    color: white;
    font-size: 0.7em;
    font-weight: bold;
    padding: 10px 20px;
    cursor: pointer;
    transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.button:hover {
    transform: scale(1.05);
    background: linear-gradient(90deg, #1e90ff 0%, #ff69b4 100%);
}

.button:focus {
    outline: none;
}


.footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 14px;
    position: absolute;
    left: 0;
    right: 0;
    top: 80%;
    z-index: 2;

}

.footer-item {
    margin: 0 10px;
    white-space: nowrap;
    padding-bottom: 19%;

}

.footer-link {
    color: white;
    text-decoration: none;
}

.footer-link:hover {
    text-decoration: underline;
}