@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap');

.hologram-image {
    width:330px; 
    height: auto;
    border-radius: 100%;
    animation: spin 10s linear infinite; /* Continuous rotation */
    padding: 1px; /* Space between the border and the image */
    background-clip: padding-box; /* Ensures the padding is included within the border */
    box-sizing: border-box;
}

@keyframes spin {
    from {
        transform: rotateY(0deg);
    }
    to {
        transform: rotateY(360deg);
    }
}

.main-container {
    height: 120vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: white ;
    position: relative;
    box-sizing: border-box;
    padding-top: 10vh;
    z-index: 2;
}


.content {
    max-width: 960px;
    margin-left: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.content h1 {
    font-size: 3.8rem;
    font-weight: 200;
    font-family: 'Poppins', sans-serif;
}

.navigation {
    display: flex;
    justify-content: space-around;
    margin: 40px 0;
    border-bottom: 2px solid #575757;
    width: 60%;
    gap: 30px;
}

.navigation a {
    color: #818181;
    text-decoration: none;
    padding-bottom: 15px;
    background-image: linear-gradient(to right, cyan, magenta, pink);
    background-repeat: no-repeat;
    background-size: 0% 9px;
    background-position: 0% 100%;
    z-index: 0;
    transition: background-size 0.3s;
    width: auto;
    font-size: 1.4rem;
    font-weight: 200;
    width: 100%;
}

.navigation a.active {
    background-size: 100% 2px;
    color: white;
}


.text-content {
    width: 50%;
    height: auto;
    margin-top: 0%;
}

.text-content h2 {
    color: #80d0c7; 
}

.text-content p {
    text-align: justify;
}


.styled-button {
    display: inline-flex;
    align-items: center;
    margin-top: 20%;
    padding: 20px 20px;
    background: transparent;
    color: white;
    text-decoration: none;
    font-size: 1em;
    border-radius: 15px;
    border: 2px solid #7a8ea3;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    width: 90%;
    justify-content: space-between;
    &:hover {
      box-shadow: 0px 0px 30px 16px #8500458a;
      background-color: #f0007d;
    }

    .button-text {
      margin-right: 10px;
    }

    .button-icon {
      font-size: 1.2em;
    }
  }