
/* Page Second Section */
.page-second-section {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.page-second-section::before,
.page-second-section::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 10vh; /* Adjust height as needed */
  background: rgb(0, 0, 0); /* Blurry effect color */
  filter: blur(100px); /* Blurriness */
  z-index: 1; /* Ensures it's above other content */
  pointer-events: none; /* Ensures it doesn't block other interactions */
}

.page-second-section::after {
  bottom: 0;
  height: 10vh; /* Adjust height as needed */
  top: auto; /* Ensure it is positioned at the bottom */
}

.page-second-section .background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

/* Animated Circles */
.circle {
  position: absolute;
  border-radius: 50%;
  filter: blur(90px);
  animation: moveCircle 10s infinite alternate;
  z-index: 1; /* Ensure circles are above the background but below the content */
  margin-left: 20vw;
}


.circle.purple {
  background: #742d78;
  width: 15%;
  height: 15%;
  top: 20%;
  left: 15%;
  z-index: 2;
}

.circle.green {
  background-color: rgb(22, 73, 99);
  width: 15%;
  height: 15%;
  top: 10%;
  left: 20%;
}

.circle.blue {
  background: rgb(33, 63, 77);
  width: 15%;
  height: 15%;
  top:20%;
  left: 30%;
}

@keyframes moveCircle {
  0% {
    transform: translate(0px, 20px);
  }
  50% {
    transform: translate(0px, 100px);
  }
  100% {
    transform: translate(200px, 100px);
  }
}
